
import Link from 'next/link'
import { useEffect } from "react";

import MapSvg from './map-svg';

export default function ContactMap({siteEnv, isContact=false}) {

    
    useEffect(() => {
        if(typeof(window) !== "undefined"){
            $ = window.jQuery;
            setTimeout(function () {
                $("#digital-scroll").animate({opacity: "1"}, 3000, mina.easeOut);
            }, 1500);
        }
    });

    return(
        <>
            <section className="mapContainer bg-white z-10" id="zone8">
                <MapSvg ></MapSvg>
                <div className="container grid grid-cols-1 lg:grid-cols-12 items-center z-20 ml-0">
                    <div className="col-span-1 lg:col-span-7 h-full items-center grid grid-cols-12 p-4 md:pl-24">
                        <div className="grid grid-cols-12 col-span-12">
                            <div className="col-span-12 p-0 mb-10">
                                <p className="contact_title"><i className="fa fa-phone-alt icon" aria-hidden="true"></i> Appellez <span>nous</span></p>
                                <p className="phone-number">01 47 70 19 74</p>
                            </div>
                            <div className="col-span-12 p-0 mb-10 grid grid-cols-12">
                                <p className="contact_title col-span-12"><i className="fa fa-map-marker-alt icon" aria-hidden="true"></i> Venez nous <span>rencontrer</span></p>
                                <p className="coord  col-span-12"></p>
                                <ul className="no-padding col-span-12">
                                    <li>5 rue Ponscarme - 75013 Paris</li>
                                    <li>
                                        <img src="/img/metro.png" style={{width:"15px", marginRight:"3px"}} alt="logo metro"/><img src="/img/metro14.png" alt="logo metro ligne 14" style={{width:"17px", marginRight:"8px"}}/>
                                            Métro Olympiades
                                    </li>
                                </ul>
                                <ul className="col-span-6 lg:col-span-3 xl:col-span-4 no-padding hidden-sm-down">
                                    <li><a className="link-plus" target="_blank" rel="noreferrer noopener" alt="position alphalives" href="https://www.google.fr/maps/place/5+Rue+Ponscarme,+75013+Paris/@48.8266,2.3660331,17z/data=!3m1!4b1!4m5!3m4!1s0x47e672263910be1f:0x349dee3fc4f9c9ce!8m2!3d48.8266!4d2.3682218">Calculer votre itinéraire</a></li>
                                </ul>
                                <div className="hidden-sm-down col-span-6 lg:col-span-3 link-hr"></div>
                                <p></p>
                            </div>
                            <div className="col-span-12 p-0 mb-10 grid grid-cols-12">
                                <p className="contact_title col-span-12"><i className="fa fa-envelope icon" aria-hidden="true"></i> Contactez-<span>nous</span></p>
                                <ul className="col-span-6 lg:col-span-3 xl:col-span-4 no-padding hidden-sm-down">
                                    <li>
                                    {isContact ? (
                                        <>
                                            <div className="link-plus scrollTo" data-id="zone7">Formulaire de contact</div>
                                        </>
                                    ) : (
                                        <>
                                            <a href="/contact#zone7" className="link-plus" title="Nous contacter">Formulaire de contact</a>
                                        </>
                                    )}
                                    </li>
                                </ul>
                                <div className="hidden-sm-down col-span-6 lg:col-span-3 link-hr"></div>
                            </div>
                        </div>
                        
                    </div>
                
                </div>
                    {isContact ? (
                        <>
                            <a data-id="zone7" className="scrollTo content-scroll-down orange" id="digital-scroll">
                                <div className="block-round"></div>
                                <span className="text-sm block content-text">
                                    Nous contacter
                                </span>
                            </a>
                        </>
                    ): (
                        <>
                            <a className="content-scroll-down orange" href="/contact#zone7" title="Nous contacter">
                                <div className="block-round"></div>
                                <span className="text-sm block content-text">
                                    Nous contacter
                                </span>
                            </a>
                        </>
                    ) }
            </section>
        </>
    )
}